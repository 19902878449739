<div class="headerBanner">
  <nav [ngClass]="{'hideForMobile' : !isMobileMenuOpen}">

    <a class="headerImg" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_HOME]">
      <img ngSrc="assets/brand/logoAndTitleReverseBlue-400.webp" width="400" height="84"
           alt="PortAgile logo et titre" priority>
    </a>

    <ul class="headerDropdownNav hiddenOnMobile">
      <li class="headerDropdownContainer">
        <h2 id="dropdownTitle" class="headerH2">Notre offre</h2>
        <ul class="headerDropdownContent">
          <a class="headerDropdownItem" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_NOTRE_OFFRE]">
            <li>
              <h2 class="headerH2">Notre offre</h2>
              <p>Decouvrez les avantages de notre offre de portage</p>
            </li>
          </a>
          <a class="headerDropdownItem" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_COOPTATION]">
            <li>
              <h2 class="headerH2">La cooptation</h2>
              <p>Les <strong>+</strong> de la cooptation avec Portagile</p>
            </li>
          </a>
        </ul>
      </li>
    </ul>

    <a class="hiddenOnMobile" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_REMUNERATION]">
      <h2 class="headerH2">Votre remunération</h2>
    </a>

    <a class="hiddenOnMobile" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_TROUVER_UNE_MISSION]">
      <h2 class="headerH2">Trouver une mission</h2>
    </a>

    <a class="hiddenOnMobile" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_FOIRE_AUX_QUESTIONS]">
      <h2 class="headerH2">Questions / Réponses</h2>
    </a>

    <a class="headerButton hiddenOnMobile" (click)="closeMenu()" [routerLink]="[PORTAGILE_ROUTES_CONTACT]">
      <h2 class="headerH2">Nous contacter</h2>
    </a>

  </nav>

  <img (click)="toggleMenu()" class="headerMenuToggleButton" ngSrc="assets/icons/burger-menu-icon.svg" height="45"
       width="45"
       alt="Menu burger logo" priority>
</div>
