import { Component } from '@angular/core';
import {VisibilityDirective} from "../../../directives/observe-visibility.directive";

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [
    VisibilityDirective,
  ],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.css'
})
export class HowItWorksComponent {

}
