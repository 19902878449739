import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appObserveVisibility]',
  standalone: true
})

export class VisibilityDirective {
  @Input() targetSelectors: string = "";

  constructor(private elementRef: ElementRef) {
    const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          this.elementRef.nativeElement.classList.add('visible');
          const relatedElements = this.elementRef.nativeElement.parentElement.querySelectorAll(this.targetSelectors);
          relatedElements.forEach((element: HTMLElement) => {
            element.classList.add('visible');
          });
          observer.unobserve(entry.target);
        }
      });
    });
    observer.observe(this.elementRef.nativeElement);
  }
}
