export enum AppRoutes {
  HOME = '',
  NOTRE_OFFRE = 'notre-offre',
  TROUVER_UNE_MISSION = 'trouver-une-mission',
  FOIRE_AUX_QUESTIONS = 'foire-aux-questions',
  CONTACT = 'contact',
  CGU = 'privacy-policy',
  COOKIES = 'gestion-cookies',
  REMUNERATION = 'votre-remuneration',
  COOPTATION = 'cooptation',
}
