import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {AppRoutes} from "../../../routing/app-routes.enum";
import {RouterModule} from "@angular/router";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterModule,
    NgOptimizedImage,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  public readonly PORTAGILE_ROUTES_CGU: string = AppRoutes.CGU
  public readonly PORTAGILE_ROUTES_COOKIES: string = AppRoutes.COOKIES
}
