import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-feps',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './feps.component.html',
  styleUrl: './feps.component.css'
})
export class FEPSComponent {

}
