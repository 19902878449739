<div class="demarcheContainer">
<!--  <div class="custom-shape-divider-top-1717426596">-->
<!--    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">-->
<!--      <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"-->
<!--            class="shape-fill"></path>-->
<!--    </svg>-->
<!--  </div>-->
  <h2 class="titleDemarche">La Démarche</h2>
  <br>
  <div class="process-wrapper">
    <div id="progress-bar-container">
      <ul>
        <li class="step step01 firstColor visibleStepPoint active">
          <div class="step-inner">Validation de la prestation</div>
        </li>
        <li class="step step02 secondColor visibleStepPoint">
          <div class="step-inner">Signature de la Convention d’Adhésion</div>
        </li>
        <li class="step step03 thirdColor visibleStepPoint">
          <div class="step-inner">Signature de votre contrat de travail</div>
        </li>
        <li class="step step04 firstColor">
          <div class="step-inner">Titre Signature du contrat commercial</div>
        </li>
        <li class="step step05 secondColor">
          <div class="step-inner">Titre Souscriptions administratives</div>
        </li>
        <li class="step step06 thirdColor">
          <div class="step-inner">Démarrage de la prestation</div>
        </li>
        <li class="step step07 firstColor">
          <div class="step-inner">Titre Renseignement d’activité mensuelle</div>
        </li>
        <li class="step step08 secondColor">
          <div class="step-inner">Versement de votre salaire et de vos frais</div>
        </li>
        <li class="step step09 thirdColor">
          <div class="step-inner">Envoi de votre Fiche de Paie</div>
        </li>
        <li class="step step10 firstColor">
          <div class="step-inner">Facturation du client</div>
        </li>
        <li class="step step11 secondColor">
          <div class="step-inner">Suivi encaissement client</div>
        </li>
        <li class="step step12 thirdColor">
          <div class="step-inner">Titre Communication</div>
        </li>
        <li class="step step13 firstColor">
          <div class="step-inner">Titre Entretien professionnel</div>
        </li>
        <li class="step step14 secondColor">
          <div class="step-inner">Titre fin du contrat commercial</div>
        </li>
        <li class="step step15 thirdColor">
          <div class="step-inner">Demande de fin de votre contrat de travail</div>
        </li>
        <li class="step step16 firstColor">
          <div class="step-inner">Titre Fourniture
          </div>
        </li>
      </ul>

      <div id="line">
        <div id="line-progress"></div>
      </div>

    </div>

    <div id="progress-content-section">
      <div id="triangleArrowLeft" class="step"></div>
      <div class="section-content step01Content active">
        <h2>Validation de la prestation</h2>
        <p>Le Salarié Porté est son propre patron dans le choix et l’organisation de ses missions et de ses clients ; et
          à
          ce titre il doit trouver sa prestation ou sa mission. Il peut s’appuyer sur d’autres structures pour l’aider
          dans sa démarche ; PortAgile peut l’assister dans cette recherche grâce notamment à l’appui du Groupe E-NOV et
          son réseau de clients et partenaires dans les métiers de l’IT. PortAgile vous aide aussi pour le Référencement
          chez vos clients, la contractualisation, ou la réponse à appels d’offre.</p>
      </div>

      <div class="section-content step02Content">
        <h2>Signature de la Convention d’Adhésion</h2>
        <p>La Convention d’Adhésion ou de Portage décrit les modalités de fonctionnement et de garanties entre le
          Salarié
          Porté et PortAgile. Elle peut être signée dès lors que vous souhaitez collaborer et travailler avec PortAgile
          sans attendre obligatoirement l’obtention d’une mission ou prestation (Elle n’implique aucun frais, aucune
          charge).</p>
      </div>

      <div class="section-content step03Content">
        <h2>Signature de votre contrat de travail</h2>
        <p>Avant de signer votre contrat de travail, il faut s’assurer que la Convention d’Adhésion soit bien signée par
          les 2 parties, et que la Société Cliente ait bien validé le démarrage de la prestation. Le contrat de travail
          CDI ou CDD proposé par PortAgile, se réfère à la Convention collective du Portage Salarial; Il est établi
          entre
          le Salarié Porté et PortAgile. Il est conclu pour la réalisation d'une prestation dans une ou plusieurs
          sociétés
          clientes et précise toutes les modalités de la collaboration (Fonctions, Rémunération, Congés, Réserve,
          Couverture sociale…) par rapport à cette prestation négociée. Il devra bien sûr être validé par les 2
          parties.</p>
      </div>

      <div class="section-content step04Content">
        <h2>Signature du contrat commercial de prestation de service</h2>
        <p>Le contrat de travail devra être signé au préalable. Le contrat commercial de prestation est signé entre
          PortAgile et La société Cliente. Ce contrat précise la prestation ou la mission, son lieu et ses conditions
          d’exécution, de télétravail éventuelles, les moyens mis en œuvre pour accomplir la prestation, sa
          tarification,
          sa durée ou le nombre de jours planifiés, et enfin les conditions et le mode de paiement. Il peut également y
          avoir des conditions particulières , telles que le remboursement de frais , ou une clause de confidentialité .
          Il doit également comporter certaines mentions obligatoires : La Responsabilité juridique, l’identité du
          garant
          financier , Le numéro d’assurance responsabilité civile professionnelle</p>
      </div>

      <div class="section-content step05Content">
        <h2>Souscriptions administratives (Urssaf, Mutuelle, Pole Santé, …)</h2>
        <p>PortAgile effectue la déclaration URSSAF, l’enregistrement auprès de la Mutuelle (Tarif négocié), La
          Prévoyance. Nous organisons la visite médicale et mettons à votre disposition le livret d’accueil PortAgile,
          ainsi que les outils pour la saisie de votre activité, et la gestion de votre compte.</p>
      </div>

      <div class="section-content step06Content">
        <h2>Démarrage de la prestation</h2>
        <p>L’ensemble des contrats, conventions et compte ont été délivrés et validés, le Salarié Porté peut désormais
          démarrer sont 1ier jour de prestation, défini préalablement avec la société cliente et PortAgile.</p>
      </div>

      <div class="section-content step07Content">
        <h2>Renseignement de votre d’activité mensuelle (CRA pour le 25 de chaque mois)</h2>
        <p>Le salarié Porté saisit son activité journalière ; quant à PortAgile, elle contrôle et valide ce compte rendu
          d’activité, appelé plus communément le CRA. Les congés doivent être signifiés 48h00 ouvrables avant leurs
          prises
          effectives et les arrêts de travail communiqués dans les 7h00 ouvrables qui suivent l’arrêt.</p>
      </div>

      <div class="section-content step08Content">
        <h2>Versement de votre salaire et de vos frais</h2>
        <p>PortAgile, effectue le paiement de la rémunération que vous souhaitez à raison du solde disponible suffisant
          et
          sur la condition du salaire minimum légal. Le calcul inclut les cotisations sociales, fiscales et les charges
          conventionnelles, directement versées aux organismes correspondants. A travers la déclaration de vos frais
          (Note
          de frais), PortAgile effectue le remboursement de l’intégralité de vos frais professionnels du mois qu’ils
          soient facturés ou non à vos clients ; Ceci dans le respect des règles Urssaf et dans la limite du solde
          disponible sur votre compte d’activité. Nous n’attendons pas d’être payés pour vous payer. Vous pouvez opter
          pour un versement à la fin du mois ou à 10 jours du mois M+1 avec des frais de gestion PortAgile minorés.</p>
      </div>

      <div class="section-content step09Content">
        <h2>Envoi de votre Fiche de Paie</h2>
        <p>PortAgile s’occupe d’établir le bulletin de salaire et de le transmettre mensuellement. Il sera déposé dans
          son
          coffre-fort personnel (En cours de mise en œuvre).</p>
      </div>

      <div class="section-content step10Content">
        <h2>Facturation du client</h2>
        <p>PortAgile établit la facturation à la Société Cliente chaque fin de mois sur la base du compte rendu
          d’activité
          (CRA) du Salarié Porté. La réception du Bon de Commande émanant de la Société Cliente est un préalable à la
          l’exécution de la facturation.</p>
      </div>

      <div class="section-content step11Content">
        <h2>Suivi encaissement client</h2>
        <p>PortAgile s’assure du paiement dans les délais des Sociétés Clientes. PortAgile peut être amené, au cours de
          sa
          procédure de recouvrement, de vous solliciter, en cas de litige.</p>
      </div>

      <div class="section-content step12Content">
        <h2>Mise à jour et Communication de votre compte d’activité mensuel</h2>
        <p>Le Salarié Porté dispose d’un Compte d’Activité mensuel et consolidé annuellement, dans lequel il va
          retrouver
          des compteurs et des indicateurs sur ces jours travaillés, d’absences et d’arrêts éventuels par rapport à ses
          différentes prestations réalisées. Ce Compte d’Activité contient également le montant du Chiffre d’affaire
          mensuel ainsi que le cumulé annuel, les différentes charges patronales, sociales, fiscales et frais mutuelle
          et
          professionnels, du Salarié Porté. Pour que le Salarié Porté puisse se positionner, le compte d’activité
          indique
          également, le solde disponible, prévisionnel via le dernier CRA réceptionné et le solde projeté à travers le
          contrat commercial, afin de pouvoir définir le versement mensuel du salaire et des frais envisagés.</p>
      </div>

      <div class="section-content step13Content">
        <h2>Entretien charge de travail et Entretien professionnel</h2>
        <p>PortAgile organise 1 fois par an un entretien de suivi de l’activité du Salarié Porté par rapport à sa charge
          de travail professionnelle afin de prévenir des risques psychosociaux. Ce dernier sera suivi d’un entretien
          professionnel pour effectuer le bilan du parcours professionnel dans la société cliente et identifier les
          compétences à développer pour la mise en place éventuelle d’un plan de formation adapté et personnalisé. Le
          Salarié Porté a également accès à tous les dispositifs de formation continue (CPF, Développement compétences,
          VAE. ).</p>
      </div>

      <div class="section-content step14Content">
        <h2>Prise d’effet de la fin du contrat commercial</h2>
        <p>Le Salarié Porté a arrêté sa prestation pour la Société Cliente. Cette dernière avait en amont informé le
          Salarié Porté et PortAgile de la date de fin du Contrat Commercial de Prestation. Le contrat est donc dénoncé
          et
          le Salarié Porté repart ou pas dans la recherche d’une autre mission ou prestation. La fin de la prestation ou
          du contrat commercial n’oblige pas à la fin du contrat de travail avec PortAgile; Tant que les réserves
          financières le permettent, votre versement de salaire peut être lissé ou sinon il est suspendu. La période de
          suspension est limitée à 3 mois.</p>
      </div>

      <div class="section-content step15Content">
        <h2>Demande de fin de votre contrat de travail</h2>
        <p>Suite à l’arrêt de la Prestation ou pour des raisons personnelles ou encore par manque de réserve financière,
          le Salarié Porté peut demander à PortAgile un accord commun pour rompre le contrat. PortAgile privilégie la
          rupture conventionnelle plutôt que la démission. La rupture de Contrat de Travail s’effectue ainsi de manière
          bienveillante et le montant de la rupture conventionnelle est calculé en fonction du salaire brut et de
          l’ancienneté.</p>
      </div>

      <div class="section-content step16Content">
        <h2>Fourniture de votre Attestation de Travail et de votre solde de tout compte (Réserve financière
          incluse)</h2>
        <p>PortAgile se doit de fournir au Salarié Porté dans un délai de 30 jours ouvrés, à date effective de fin du
          contrat de travail, l’Attestation de Travail qui permettra au Salarié Porté de percevoir ses droits au chômage
          et s’inscrire au Pôle Emploi. Le Salarié Porté reçoit bien sûr dans le même temps le règlement pour solde de
          tout compte.</p>
      </div>
      <div id="triangleArrowRight" class="step step02 visibleStepPoint"></div>
    </div>
  </div>
<!--  <div class="custom-shape-divider-bottom-1717427544">-->
<!--    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">-->
<!--      <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"-->
<!--            class="shape-fill"></path>-->
<!--    </svg>-->
<!--  </div>-->
</div>
