<div class="teamContainer">
  <div class="titleContainer">
    <div class="custom-shape-divider-top-1717576032">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"></path>
      </svg>
    </div>
    <h2 class="titleTeam">Notre équipe</h2>
    <div class="custom-shape-divider-bottom-1718011578">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path
          d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
          class="shape-fill"></path>
      </svg>
    </div>
  </div>

  <div class="teamContent">
    <div class="our-team">
      <div class="pic">
        <img
          src="https://media.licdn.com/dms/image/C4D03AQHYWclDQC14MA/profile-displayphoto-shrink_800_800/0/1633976798875?e=1725494400&v=beta&t=fRKCmKWy2Htr7YcqufUrDSU2TtTLPETX1aNFzly3aIg"
          alt="">
      </div>
      <div class="team-content">
        <h3 class="title">Aurore Zabinski</h3>
        <span class="post">Web Developer</span>
      </div>
    </div>

    <div class="our-team">
      <div class="pic">
        <img
          src="https://media.licdn.com/dms/image/D4E03AQGwlMABCQOs3w/profile-displayphoto-shrink_200_200/0/1686212349784?e=2147483647&v=beta&t=QNw9lCNT10LUEHYPscwHRwsfHAn3HxdKg7ei7qdqKcQ"
          alt="">
      </div>
      <div class="team-content">
        <h3 class="title">Yvelise Potie</h3>
        <span class="post">Web Developer</span>
      </div>
    </div>

    <div class="our-team">
      <div class="pic">
        <img
          src="https://img.freepik.com/photos-gratuite/mobile_677411-2572.jpg?size=338&ext=jpg&ga=GA1.1.1413502914.1713571200&semt=ais"
          alt="">
      </div>
      <div class="team-content">
        <h3 class="title">Valentin Renard</h3>
        <span class="post">Web Designer</span>
      </div>
    </div>

  </div>
  <div class="aboutUs">
    <h3>Quelques mots à propos de nous</h3>
    <p>
      <strong>PortAgile</strong> est une société de portage salarial à taille humaine, créée par deux consultants qui
      s’appuient sur une solide expérience de l’entreprenariat, du droit salarial et des affaires.
    </p>
    <p>
      Structure souple et adaptative, PortAgile intervient en intermédiaire entre une société cliente et un salarié&nbsp;‘porté’,
      pour conjuguer les aspirations de chacun.
    </p>
    <p>
      <strong>L’équipe</strong> est disponible pour vous conseiller, vous faire avancer dans vos réflexions, ou vous
      faire progresser dans votre carrière. Elle vous acceuille dans nos locaux, 1 rue Jacques Prévert à Villeneuve
      d’Ascq.
    </p>
    <p>
      <strong>Le groupe</strong> propose également des services, pour l’accompagnement des salariés portés dans la
      recherche d’une mission ou d’un client chez lequel il pourra offrir ses propres services et s’épanouir
      professionnellement.
    </p>
    <p class="textCenter">
      Jean-Michel Zabinski<br>
      Christophe Renard
    </p>
  </div>
</div>
