import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";

@Component({
  selector: 'app-gestion-cookies',
  standalone: true,
  imports: [
    H1TemplateComponent
  ],
  templateUrl: './gestion-cookies.component.html',
  styleUrl: './gestion-cookies.component.css'
})
export class GestionCookiesComponent {

}
