import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-h1-template',
  standalone: true,
  imports: [],
  templateUrl: './h1-template.component.html',
  styleUrl: './h1-template.component.css'
})
export class H1TemplateComponent {
  @Input() title: string ="";
}
