<div class="FAQContainerDiv">
  <app-h1-template [title]="'Foire aux Questions'"/>
  <div class="FAQGlobalDiv">
    <div class="FAQDiv">
      <h2>Conditions pour être Salarié Porté</h2>
      <div class="FAQContentDiv1">
        <div class="question q1">
          <h3>Quel niveau de qualification ou d'expérience est exigé pour être Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q1">Le Salarié Porté doit avoir au minimum une qualification professionnelle de niveau 5
          (Bac +2) ou une expérience significative d'au moins 3 ans dans le même secteur d'activité.
        </div>
        <div class="question q2">
          <h3>En retraite, puis-je exercer une activité en statut Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q2">Oui, séniors actifs, en pré-retraite, ou retraités, vous pouvez rester actif et
          transmettre votre expertise tout en percevant votre pension de retraite. Vous êtes autonome tout en
          bénéficiant d’une couverture sociale de qualité. Vous pouvez ainsi cumuler votre pension de retraite et une
          activité en portage salarial.
        </div>
        <div class="question q3">
          <h3>Quel est le Chiffre d’Affaire mensuel minimum à réaliser pour être Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q3">D’un point de vue légal, la rémunération brute mensuelle totale ne peut pas être
          inférieure à 2 517,13 euros pour pouvoir exercer son activité en portage salarial, cela veut dire que le
          salarié porté doit vendre ses prestations à un tarif supérieur à environ 270€ HT par jour presté (environ
          38€ HT / heure).<br><br>

          Nous considérons une moyenne de 18 jours travaillés par mois, le Salarié Porté doit alors réaliser un
          chiffre d’affaire mensuel minimum de 4 860€ HT.<br><br>

          La rémunération mensuelle du Salarié Porté peut-être variable, puisqu’elle est calculée sur la base du «
          Montant Disponible » sur son compte d’activité, c’est-à-dire du chiffre d’affaires facturé à ses clients et
          encaissé par l’Entreprise de Portage Salarial, déduction faite de ses frais de gestion.
        </div>
        <div class="question q4">
          <h3>En tant que Salarié Porté, puis-je exercer indéfiniment mon activité dans le même
            service et chez le même client&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q4">La mission ou la prestation effectuée en France comme à l’étranger de manière
          ponctuelle, ne pourra pas excéder 3 ans. Dépassé ce délai il vous faudra démarrer une nouvelle mission dans
          un autre service ou chez un autre client.
        </div>
        <div class="question q5">
          <h3>Quels métiers sont éligibles au statut de Salarié porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q5">Le Portage Salarial est possible avec certains métiers et activités de conseil, de
          service et de prestation intellectuelle pour une clientèle de professionnels.<br><br>

          Les activités de commercial ou d’apporteur d’affaire peuvent être prises en compte mais uniquement si les
          missions assurent une rémunération fixe suffisante, à un rythme occasionnel.<br><br>

          Les métiers de l’informatique : webmaster, développeur de logiciel, programmeur informatique, ingénieur
          système, directeur des systèmes d’information (DSI), formateur en informatique, Administrateur
          Système.<br><br>

          Les métiers du web et du digital : rédacteur web, community manager, consultant SEA, consultant SEO,
          consultant webmarketing, blogueur, Youtuber, Product Owner, Business Analyste, Coach Agile, Ingénieur DevOps
          etc.<br><br>

          Les métiers de la traduction : traducteur, interprète etc.<br><br>

          Les métiers des ressources humaines : consultant RH, consultant en management etc.<br><br>

          Les métiers de coaching : coach dans différents domaines d’activités.<br><br>

          Les métiers de communication et marketing : consultant marketing, consultant en communication etc.<br><br>

          Les métiers de design et de graphisme : UX designer, infographiste, graphiste, motion designer etc.<br><br>

          Les métiers de la photographie et artistique : photographe, directeur artistique etc.<br><br>

          Les métiers de la formation : formateur dans différents domaines d’activités comme formateur web, formateur
          en anglais, formateur en communication, formateur commercial, formateur en management etc.<br><br>

          **Les métiers de la finance **: conseiller financier, manager de transition (dans une opération de
          fusion-acquisition par exemple), gestionnaire de patrimoine etc.<br><br>

          Les métiers de l’industrie : ingénieur qualité, consultant dans l’industrie pharmaceutique ou
          agroalimentaire, consultant qualité, concepteur de dispositif industriel, data scientist etc.<br><br>

          Les métiers du management d’équipe ou de projet : Transition manager, chef de projet, scrum master etc.
        </div>
      </div>
    </div>

    <div class="FAQDiv">
      <h2>Droit du travail et du Salarié Porté</h2>
      <div class="FAQContentDiv2">
        <div class="question q6">
          <h3>
            Le Salarié Porté peut-il s’abstenir de la prise de congés&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q6">
          Non, une prise de congé minimale est obligatoire à hauteur de 12 jours ouvrables consécutifs dans l’année.
          Le Salarié Porté provisionne par défaut 2,5 jours ouvrables par mois d’activité (soit 30 jours ou 5
          semaines); ils sont donc reversés lors de la prise de congés. Cependant, par demande exprimée lors de
          l’établissement du contrat de travail, il est possible de ne pas provisionner les congés mais de verser
          mensuellement une indemnité compensatrice de Congé payés; Dans ce cas, tout congé sera pris sans être
          rémunéré.
        </div>
        <div class="question q7">
          <h3>
            Le Salarié Porté travaille-t-il les jours fériés&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q7">
          Seul le 1er Mai est obligatoirement chômé sauf disposition règlementaire. Les jours fériés qui ne sont pas
          travaillés, ne donne pas lieu à rémunération.
        </div>
        <div class="question q8">
          <h3>
            Le Salarié Porté peut-il adopter le rythme de travail qui lui convient&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q8">
          Le Salarié Porté adopte le rythme de travail qu’il a convenu avec son client, en respect des durées
          maximales de travail (soit 48h maximum de manière exceptionnelle ; 46h par semaine sur une durée limitée)
          et
          du droit au repos (11 heures consécutives entre 2 journées de travail et un repos hebdomadaire de
          24h+11h).
        </div>
        <div class="question q9">
          <h3>
            Que se passe t’il en fin de mission pour le Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q9">
          A la fin du contrat de travail en portage salarial, le Salarié Porté fait valoir ses droits aux indemnités
          chômage dans les conditions dé­finies actuellement par la circulaire Unedic n°2015-10 du 11 juin 2015 et
          l’instruction Pôle Emploi n°2015-50 du 31 juillet 2015.
        </div>
        <div class="question q10">
          <h3>
            Le Salarié Porté, peut-il cumulé plusieurs emplois, missions ou contrats commerciaux&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q10">
          Oui, Il est possible sous certaines conditions de cumuler plusieurs emplois (par exemple un emploi
          classique
          pour une entreprise en salariat classique et une autre mission ou prestation complémentaire en portage
          salarial. Quant aux missions en portage salarial, elles peuvent être multiple et prestées en temps partagé
          pour des Sociétés clientes différentes.
        </div>
      </div>
    </div>

    <div class="FAQDiv">
      <h2>Responsabilités du Salarié Porté et de la Société de Portage</h2>
      <div class="FAQContentDiv3">
        <div class="question q11">
          <h3>
            Quelle sont les responsabilités du Salarié Porté vis-à-vis de la société de portage&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q11">
          <ul>
            <li>
              Mettre en œuvre les moyens nécessaires pour réaliser et mener à son terme chaque prestation, selon les
              termes et conditions du contrat commercial qu'il a négocié .
            </li>
            <li>
              Mettre en œuvre les moyens nécessaires pour développer son activité.
            </li>
            <li>
              Rendre compte de son activité à la société de portage au moins 1 fois par mois.
            </li>
            <li>
              Prévenir de toute difficulté empêchant la bonne exécution de sa mission.
            </li>
          </ul>
        </div>
        <div class="question q12">
          <h3>
            Quelle sont les documents que la Société de Portage doit fournir au Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q12">
          <ul>
            <li>
              La Convention d’Adhésion entre le Salarié Porté et la Société de Portage.
            </li>
            <li>
              Le Contrat de travail et les formalités qui y sont liées : déclarations, rémunération, paiement.
            </li>
            <li>
              Le Contrat Commercial de Prestation : Nature et Conditions de la prestation pour la Société Cliente.
            </li>
          </ul>
        </div>
        <div class="question q13">
          <h3>
            Quelle sont les responsabilités de la Société de Portage vis-à-vis du Salarié Porté
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q13">
          <ul>
            <li>
              Souscriptions Administrative : Enregistrements Urssaf, Pole Santé, Mutuelle, Prévoyance, …
            </li>
            <li>
              Gérer un compte d'activité pour le salarié porté : Temps, Charges, Chiffre d’Affaire, Disponible.
            </li>
            <li>
              S'assurer et suivre l'adaptation au poste de travail du salarié et vérifier de ses compétences pour
              développer son projet professionnel.
            </li>
          </ul>
        </div>
      </div>
    </div>


    <div class="FAQDiv">
      <h2>Salaire, Frais professionnels, Paiements, Impôt</h2>
      <div class="FAQContentDiv4">
        <div class="question q14">
          <h3>
            Comment contrôler son bulletin de salaire en tant que Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q14">
          Cela consiste à remonter le cheminement du calcul, de votre rémunération à votre chiffre d’affaires avec
          la
          formule ci-dessous :<br><br>

          Total brut + Cotisations patronales + Réserves + Frais professionnels + Frais de gestion = CA HT du
          mois.<br><br>

          Le total brut qui figure sur le bulletin de salaire (BS), « Rémunération brute ». Les cotisations
          patronales
          qui figurent au pied du BS à la case « Cotisations patronales / Mensuel » Le cas échéant, le montant des
          Frais
          professionnels dans la colonne « A payer » au salarié.<br><br>

          Le montant obtenu doit correspondre au montant du disponible pour le calcul de la rémunération du
          mois.<br><br>

          Ajouter à ce montant les montants déduits sur votre compte d’activité : Le montant des Frais de gestion
          prélevés par votre société de Portage Salarial qui doit être affiché sur votre compte d’activité. /!\Les
          montants de la ‘réserve’ effectués par la société de Portage Salarial ou le Salarié Porté n’entrent pas en
          compte dans le calcul du bulletin de salaire du mois. (Réserves financières légales, provisions pour
          charges,
          épargne salariale, solde réservé par le salarié …).<br><br>

          **Déduisez **de ce montant les montants éventuellement crédités sur votre compte d’activité :<br><br>

          <ul>
            <li>
              La « réserve financière » disponible, créditée à votre compte d’activité.
            </li>
            <li>
              Les provisions réservées par la société de Portage Salarial(Charges, Congés, indemnités …)
              disponibles,
              créditée à votre compte d’activité.
            </li>
            <li>
              Les montants crédités par la société de Portage Salarial à divers titres (Primes parrainages …)
            </li>
            <li>
              Les provisions réservées par le salarié porté créditées à votre compte d’activité, si vous avez
              demandé
              à
              utiliser tout ou partie de votre « solde disponible » (Lissage de salaire).
            </li>
          </ul>
          Le total obtenu doit correspondre au montant de votre chiffre d’affaires HT encaissé ou facturé sur le
          mois.
        </div>
        <div class="question q15">
          <h3>
            La gestion des frais professionnels&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q15">
          Il faut distinguer 2 types de frais professionnels :<br><br>

          Frais non refacturés Ce sont les frais engagés dans le cadre de vos missions en portage salarial qui ne
          sont
          pas pris en charge par vos clients, et frais généraux liés à votre activité professionnelle en portage
          salarial (Télécommunications, formation, télétravail, frais de prospection…).<br><br>

          Le remboursement des frais non refacturés est bien sûr plafonné au montant disponible sur votre compte
          d’activité après paiement de la rémunération minimale légale et doit permettre d’assurer la rémunération
          minimale légale requise en portage salarial, soit un chiffre d’affaires minimum alloué au salaire de +/-
          270€
          HT/jour.<br><br>

          Frais refacturés au client Ce sont les frais engagés dans le cadre de la mission (Transport, restauration,
          hébergement sont les plus courants), pris en charge par votre client aux conditions négociées directement
          entre vous et la société cliente, qui figurent donc au contrat commercial de prestation.<br><br>

          La facturation au client se fait distinctement des services de prestation, selon les conditions et
          modalités
          contractuelles conclues avec la société cliente<br><br>

          /!\ Pour ne pas être assujettis aux cotisations sociales, CSG-CRDS, les frais professionnels doivent faire
          l’objet d’un remboursement qui respecte les limites fixées par arrêté ministériel et les trois conditions
          suivantes :<br><br>

          <ul>
            <li>
              La dépense effectuée doit être une charge spécifique inhérente à sa fonction de Salarié Porté,
            </li>
            <li>
              La dépense effectuée par le Salarié Porté doit découler directement des conditions d’exécution de sa
              mission
              ou prestation,
            </li>
            <li>
              La dépense doit être effectivement exposée par le Salarié Porté, et doit correspondre à une charge
              différente
              à celles liées à l’exercice de sa vie courante.
            </li>
            <li>
              Autrement dit, Les frais déclarés doivent être justifiables et avoir la qualification juridique de
              frais
              professionnels au sens fixés par L’article L 136-1-1 du Code de la sécurité sociale.
            </li>
          </ul>
          PortAgile contrôle chaque note de frais et se réserve le droit de ne pas rembourser des frais injustifiés
          ou
          un montant donné, qui présenterait un risque ou ne permettrait pas de répondre aux conditions légales
          requises.
        </div>
        <div class="question q16">
          <h3>
            Que se passe t’-il en cas d’impayés de la part de la Société Cliente&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q16">
          La société de portage se doit d’informer le Salarié Porté de tout versement effectué par la société
          cliente
          à
          la société de Portage Salarial au titre de la réalisation de sa prestation, et le renseignement de son
          compte
          d’activité.<br><br>

          PortAgile suit les encaissements et le règlement des éventuels retards, en vous tenant systématiquement
          informé de nos actions par anticipation pour ne jamais interférer de façon inadaptée dans votre relation
          avec
          votre client.<br><br>

          En cas de contentieux avéré et à défaut d’avoir obtenu un recouvrement amiable, PortAgile pourra mettre un
          terme à votre mission de façon anticipée, et assurera votre rémunération jusqu’au terme de votre
          prestation.
          PortAgile assumera sa responsabilité et mènera le cas échéant les recours contentieux pour faire valoir
          ses
          droits sur les sommes restées impayées.
        </div>
        <div class="question q17">
          <h3>
            Concernant les Impôts sur le Revenu, Comment cela fonctionne pour le Salarié Porté&nbsp;?
          </h3>
          <img class="plus" ngSrc="assets/icons/plus-icon.svg" width="30" height="30" alt="logo plus"
          >
        </div>
        <div class="answer q17">
          La gestion de l’impôt sur le revenu pour un Salarié Porté fonctionne à l’identique que pour un salarié
          classique, vous êtes soumis au même régime d’imposition; A ce titre, conformément à la législation en
          vigueur
          depuis le 01/2019 votre impôt sur le revenu sera directement prélevé sur votre salaire dans le cadre du
          prélèvement à la source.<br><br>

          La société de Portage salarial, en tant qu’employeur n’agit en aucun cas dans la gestion de votre impôt
          sur
          le
          revenu, il est un simple collecteur pour le compte de l’Etat, comme en matière de cotisations de sécurité
          sociale.
        </div>
      </div>
    </div>
  </div>
</div>

