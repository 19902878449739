<app-h1-template [title]="'Contact'"/>
<!--<div class="contactContainer">-->
<!--  <div class="contactFormContainer">-->
<!--    <app-contact-form/>-->
<!--    <app-cooptation-form/>-->
<!--  </div>-->
<!--</div>-->


<div class="onBuildingContainer">
  <div class="onBuildingBlock">
    <div class="onBuildingDiv">
      <h2>Page en construction</h2>
      <img ngSrc="assets/brand/logo-300.webp" alt="logo portagile" height="299" width="300">
    </div>
    <div class="onBuildingContent">
      <strong>mail :</strong> contact&#64;portagile.fr<br><br>
      <strong>téléphone :</strong> (+33) 9 67 82 35 88<br><br>
      <strong>adresse :</strong> 1 rue Jacques Prévert<br>
      59650 Villeneuve d'Ascq<br>
      Hauts de France, FRANCE<br>
    </div>
  </div>
</div>
