<div class="managementFeesContainer">
  <div class="offerCardTitle">
    <h2>Nos frais de gestion</h2>
    <p>
      Nos offres de portage salarial sont valables quelque que soit votre chiffre d’affaires et vous pouvez en changer
      gratuitement quand vous le souhaitez&nbsp;!
    </p>
  </div>
  <div class="offerCardsContainer">
    <div class="offerFirstCard">
      <div class="cardContent">
        <p class="topCaption">A partir de<sup>*</sup></p>
        <span class="mainSubject">5%</span><span class="mainCaption">du CA HT</span>
        <p class="cardCaption">Le versement du salaire s’effectue au 10 du mois M+1</p>
      </div>
    </div>
    <div class="offerSecondCard">
      <div class="cardContent">
        <p class="topCaption">A partir de<sup>*</sup></p>
        <span class="mainSubject">6%</span><span class="mainCaption">du CA HT</span>
        <p class="cardCaption">Le versement du salaire s’effectue en fin de mois</p>
      </div>
    </div>
    <div class="offerThirdCard">
      <div class="cardContent flexCenter">
        <p class="cardTitle">Remise fidélité / Montant CA&nbsp;HT</p>
        <p class="cardText">À partir d’un an d’ancienneté, nous vous verserons une prime annuelle si votre chiffre
          d’affaires dépasse certains seuils&nbsp;:</p>
        <table>
          <tr class="tableTitle">
            <td>CA HT</td>
            <td>Remise<sup>**</sup></td>
          </tr>
          <tr>
            <td>100k€ à<br> 150k€</td>
            <td>0,5%</td>
          </tr>
          <tr>
            <td>150k€ à<br> 200k€</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>A partir de<br> 200k€</td>
            <td>1,5%</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="blockCaption">
    <p style="color: #333333">*Ces tarifs s’appliquent à une facture, un compte d’activité et un seul client.</p>
    <p style="color: #333333">** En pourcentage du chiffre d’affaires annuel</p>
  </div>
</div>
<div class="offerFeesTextBlock">
  <p>
    Nos offres de portage vous assurent une optimisation de vos revenus et vous permettent également de bénéficier&nbsp;:
  </p>
  <ul>
    <li>de notes de frais professionnels dans les limites légales (sur justificatifs) jusqu'à 20% de votre CA
    <li>de fournitures de matériels professionnels achetés par la société et déduites de votre compte d’activité</li>
    <li>d’une Assurance responsabilité civile d’exploitation et professionnelle et d’une Garantie des salaires (Banque
      Populaire) en cas de défaillance de l’entreprise
    </li>
    <li>d’une prime de cooptation de 750 euros HT après 3 mois de prestation d’un nouveau Salarié Porté, issu de votre
      recommandation
    </li>
  </ul>
</div>

