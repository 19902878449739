<app-h1-template [title]="'Notre offre'"/>
<div class="offerContainer">

  <app-offer-advantages/>

  <app-management-fees/>

  <app-feps/>
</div>

