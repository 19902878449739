<app-h1-template [title]="'Mentions légales - CGU'"/>
<div class="policyContainer">
  <p>
    Les règles RGPD concernent le droit d’opposition, rectification, droit à l’oubli, portabilité des données,
    limitation
    de traitement, opposition traitement commercial
  </p>
  <p>
    Respectueux de la vie privée, PortAgile accorde une grande importance à la protection de vos données à caractère
    personnel. La présente politique vise donc à vous exposer en toute transparence les modalités et caractéristiques
    des
    traitements de vos données personnelles.
  </p>
  <h2>
    Qui sommes-nous&nbsp;?
  </h2>
  <p>
    « PortAgile » est une société de portage salarial inscrite au RCS de Lille Metropole B 913 714 614
  </p>
  <p>
    PortAgile est amenée, dans le cadre de ses missions et/ou de l’exécution de mesures précontractuelles ou
    contractuelles, à traiter vos données personnelles.
  </p>
  <p>
    PortAgile, en sa qualité de société commerciale, traite également vos données personnelles, notamment via son site
    internet et son application https://www.portagile.fr et les échanges mail, téléphoniques que vous êtes amenés à
    avoir
    avec nos services. Certaines données sont mises en commun au sein de PortAgile et de son « Réseau » incluant ses
    partenaires privilégiés que sont :
  </p>
  <p>
    E-NOV Groupe, RCS Lille xxx xxx xxx Le commissaire aux comptes, xxx Le Cabinet d’ expert paie et gestion sociale,
    Antaris, RCS xxxx Et la Société SVP SI-RH RCS Bobigny B 322 625 732, Editeur de paie sous logiciel SILAE Ainsi,
    PortAgile et ses partenaires agissent en qualité de responsables conjoints pour les traitements et les finalités
    ci-après décrits.
  </p>
  <h2>
    Quel est l’objet de ce document&nbsp;?
  </h2>
  <p>
    Cette notice a pour objet de vous informer des conditions et modalités de collecte, de traitement et d’utilisation
    de
    vos données à caractère personnel, ainsi que de vos droits dans ce cadre au regard des dispositions légales et
    réglementaires applicables en la matière.
  </p>
  <p>
    Elle est complétée par les mentions légales du site accessible sur le lien :
    https://www.portagile.fr/mentions-legales/
  </p>
  <p>
    En vous connectant au site de PortAgile, vous reconnaissez avoir pris connaissance et accepté sans réserve les
    éléments et process de protection des données confidentielles tels que décrits tant dans de la présente Politique de
    confidentialité que dans les mentions légales du site.
  </p>
  <h2>
    Quand sommes-nous amenés à traiter vos données&nbsp;?
  </h2>
  <p>
    De manière générale, vos données sont donc collectées directement auprès de vous par voie électronique, téléphonique
    ou postale. Vous vous engagez à ce que les données à caractère personnel vous concernant soient à jour, exactes,
    complètes et non-équivoques. A ce titre, vous ne pouvez transmettre ou saisir des données au lieu et place d’une
    tierce personne.
  </p>
  <p>
    Les données vous concernant sont collectées ou traitées à l’occasion de vos relations avec PortAgile. Vos données
    sont
    notamment collectées ou traitées à l’occasion de votre navigation et/ou de la saisie par vos soins d’informations
    dans
    les formulaires de collecte de données figurant sur notre site internet et application https://www.PortAgile.fr.
  </p>
  <p>
    En complément de ces informations, nous vous notifions que les données que nous collectons et traitons vous
    concernant
    peuvent éventuellement être enrichies par nos soins, notamment pour des finalités commerciales, de prospection, de
    gestion des ressources humaines, de communication ou de marketing, au moyen d’autres sources d’informations (réseaux
    sociaux, informations dites « publiques »…).
  </p>
  <p>
    Par ailleurs, s’agissant plus particulièrement des informations traitées dans le cadre de nos opérations de
    recrutement de salariés portés, nous utilisons les informations que vous nous communiquez (outil de simulation et
    formulaire à cet effet sur le site) que nous intégrons dans notre fichier commercial de prospects.
  </p>
  <h2>
    Quelles catégories de données recueillons-nous&nbsp;?
  </h2>
  <p>
    Plusieurs catégories de données à caractère personnel vous concernant peuvent être collectées, et en particulier les
    données suivantes :
  </p>
  <p>
    Des données d’identification : il s’agit notamment de données telles que votre état civil, vos nom et prénoms, vos
    coordonnées (adresse e-mail, numéro de téléphone, adresse postale), photocopie de la carte d’identité, n° de
    sécurité
    sociale, RIB, accessoirement adresse IP.
  </p>
  <p>
    Des données commerciales et financières relatives à votre projet de portage salarial (Rémunérations brute ou nette
    souhaitées, frais professionnels estimés ou réels, projet d’investissement immobilier, ect…)
  </p>
  <p>
    Votre historique de trafic et de navigation sur le site, en ce incluant des informations relatives à la traçabilité
    de
    vos actions sur et interactions avec le site, ainsi que des données de localisation (localisation de l’adresse IP
    par
    exemple), des données relatives à votre terminal ou encore des données de tracking de vos actions à l’égard des
    e-mails que nous pouvons vous adresser (ouvertures, clics,…) ;
  </p>
  <p>
    Des données relatives au suivi actuel ou futur de votre relation avec PortAgile : demande de contact, de
    renseignements ou de documentation, correspondances échangées,
  </p>
  <p>
    Des données nécessaires à l’embauche : situation familiale, financière, personnelle et professionnelle,
  </p>
  <p>
    Toutes données utiles à l’exercice de votre activité de salarié porté au sein de PortAgile (RCA),
  </p>
  <p>
    Des données nécessaires à la réalisation des actions de fidélisation, de prospection, d’étude, de sondage, de
    parrainage …
  </p>
  <p>
    Vos informations et documents (CV et copie de diplômes notamment) communiqués dans le cadre d’un recrutement en
    qualité de salarié porté, ainsi que les informations que nous pouvons recueillir à cette fin dans le cadre des
    opérations de recrutement (parcours scolaire et universitaire, formation et diplômes, expérience et parcours
    professionnel, informations relatives à la vie professionnelle de manière générale, informations d’ordre économique
    ou
    financier, informations relatives à la vie personnelle le cas échéant,…).
  </p>
  <h2>
    La collecte de ces données est-elle obligatoire&nbsp;?
  </h2>
  <p>
    Le caractère obligatoire des réponses vous est signalé sur les formulaires de collecte des données à caractère
    personnel par la présence d’un astérisque à côté du(des) champ(s) concerné(s). En l’absence d’astérisque, les
    informations demandées sont facultatives.
  </p>
  <p>
    A défaut de renseignement des informations ayant un caractère obligatoire, la demande liée à cette collecte de
    données
    (par exemple : demande de renseignement, saisie de rémunération souhaitée) risque de ne pas pouvoir être traitée ou
    d’être retardée.
  </p>
  <h2>
    A quelles fins vos données sont-elles collectées&nbsp;?
  </h2>
  <p>
    Selon les cas, nous traitons vos données collectées via les points de collecte susvisés ainsi qu’au téléphone pour
    les
    principales finalités suivantes :
  </p>
  <p>
    D’une manière générale, pour la gestion des relations commerciales comme administratives de PortAgile avec les
    candidats futurs salariés portés, ses salariés portés actifs, des tiers souhaitant être informées des nouveautés
    autour du portage salarial, et les sociétés clientes de ses salariés portés,
  </p>
  <p>
    Informer et diffuser nos informations sur le portage salarial et notre société
  </p>
  <p>
    L’accès et le bénéfice des fonctionnalités proposées sur le site et ce, notamment, concernant les outils de
    simulation
    ;
  </p>
  <p>
    La gestion et la rédaction d’un contrat de prestation de service avec la société cliente désignée par le salarié
    porté
  </p>
  <p>
    Le suivi et le traitement des candidatures et des opérations préalables au recrutement, en ce incluant l’évaluation
    de
    l’éligibilité au portage salarial et la sélection de candidatures et de profils professionnels, notamment au moyen
    de
    la constitution d’un fichier de candidats salarié porté,
  </p>
  <p>
    La rédaction de contrat de travail;
  </p>
  <p>
    La réalisation des factures
  </p>
  <p>
    La gestion des droits sociaux (PEE PERCO etc)
  </p>
  <p>
    La gestion des salaires et la communication des éléments idoines au sous-traitant de paie dûment assermenté,
  </p>
  <p>
    La gestion et la réalisation d’opérations de prospection, de sollicitation, de marketing et de communication
    personnalisées (e-mails dont newsletters et courrier postal, télémarketing, ciblage, segmentation et
    personnalisation
    des échanges, relations RH) du Réseau PortAgile. La participation à des manifestations ou des relations publiques
    organisées par PortAgile (forum de l’emploi, animation thématique, webinar, etc…)
  </p>
  <p>
    La compréhension et l’étude de l’utilisation du site par ses utilisateurs et du comportement de navigation de ces
    derniers en vue de l’amélioration de notre communication en ligne et pour prévenir et lutter contre la fraude
    informatique (spamming, hacking…);
  </p>
  <p>
    L’amélioration et l’optimisation de la qualité et des fonctionnalités du site ;
  </p>
  <p>
    La réalisation et l’élaboration d’études, d’analyses, de reporting et de statistiques ;
  </p>
  <p>
    Le respect des obligations légales et réglementaires résultant notamment de l’activité de société de Portage
    Salarial
  </p>
  <p>
    En fonction des finalités poursuivies, la base légale de tels traitements de données à caractère personnel peut
    varier. De manière générale, ces traitements sont nécessaires :
  </p>
  <p>
    Pour la gestion, le traitement et le suivi de vos demandes à l’attention de PortAgile (notamment réponse aux
    demandes
    de renseignement, étude des candidatures, gestion commerciale de votre société cliente, gestion de votre compte
    d’activités…), ces opérations pouvant dans certains cas être qualifiées d’opérations nécessaires à l’exécution d’un
    contrat auquel vous êtes partie prenante ou de mesures précontractuelles prises à votre demande ou à votre bénéfice
    (en ce, particulièrement dans le cadre de l’élaboration et du suivi de votre contrat de travail comme du/des
    contrats
    de prestation de service y afférent) .
  </p>
  <p>
    Pour la poursuite des intérêts légitimes du Réseau PortAgile dans le cadre de la gestion et du suivi de ses
    relations
    (notamment administratives, RH et commerciales) avec les utilisateurs du site, et plus généralement avec ses
    contacts,
    prospects, salariés portés, sociétés clientes, et de l’organisation de ses opérations de marketing, de prospection
    et
    de communication de manière générale.
  </p>
  <p>
    Pour le respect d’obligations légales et réglementaires qui s’imposent à PortAgile.
  </p>
  <p>
    A défaut de correspondre à l’une de ces trois bases légales, le traitement de vos données à caractère personnel sera
    fondé sur votre consentement, notamment dans le cas de la transmission de vos données à nos éventuels partenaires
    commerciaux ou de votre inscription à notre newsletter/webinar et autres évènements.
  </p>
  <h2>
    A qui vos données sont-elles destinées&nbsp;?
  </h2>
  <p>
    Le traitement et la manipulation de vos données à caractère personnel sont du ressort exclusif des services et
    personnels dûment habilités de PortAgile.
  </p>
  <p>
    Une partie de vos données personnelles peuvent être également communiquées aux sous-traitants nécessaires à son
    activité de portage salarial (notamment société de traitement de paie et Régie-Prestations), sous réserves bien sûr
    que les dites sociétés respectent des conditions strictes de confidentialité.
  </p>
  <p>
    La liste de ces sous-traitants ( logiciel de paie, expert comptable, commissaire aux comptes, assureurs, autorités
    administratives ) est disponible sur simple demande.
  </p>
  <p>
    Enfin, vos données pourront être communiquées à toute autorité légalement habilitée à en connaître, en particulier
    en
    cas de réquisition en provenance des autorités judiciaires, fiscales, sociales, policières ou administratives.
  </p>
  <p>
    Seules les données nécessaires au traitement par les destinataires visés ci-dessus sont transmises.
  </p>
  <h2>
    Combien de temps vos données sont-elles conservées&nbsp;?
  </h2>
  <p>
    De manière générale, vos données sont conservées pendant la durée qui est nécessaire à la réalisation des finalités
    susvisées, sous réserve des obligations légales ou réglementaires de conservation de certaines données (par exemple,
    délais de conservation comptables et légaux -10 ans- ou encore durée d’archivage légal des bulletins de salaires) ou
    en fonction du règlement de différends pour faire valoir, exercer et/ou défendre nos droits.
  </p>
  <p>
    A titre d’exemple :
  </p>
  <ul>
    <li>
      1 an maximum pour vos éléments de candidature déposés en ligne,
    </li>
    <li>
      RIB, Taux PAS des salariés portés => 6 ans à compter du dernier versement de salaire
    </li>
    <li>
      N° de sécurité sociale des salariés portés => 7 ans à compter du dernier versement de salaire
    </li>
    <li>
      Permis de conduire, carte grise, taxe d’habitation => 8 ans à compter du dernier versement de salaire
    </li>
    <li>
      RIB d’une entreprise cliente => 5 ans avec autorisation du client
    </li>
  </ul>
  <p>
    Il est précisé que l’ensemble de ces données peuvent toutefois être conservées plus longtemps que les durées
  </p>
  <p>
    précitées :
  </p>
  <p>
    Soit après obtention expresse de votre accord, Soit, sous forme d’archives, pour répondre aux obligations légales et
    réglementaires éventuelles qui s’imposent à PortAgile ou encore pendant les délais légaux de prescription.
  </p>
  <p>
    Toutes les durées de conservation ont été définies de manière à nous permettre de traiter vos demandes (de
    renseignements, contact, outil de simulation, demande d’entretien en ligne…) et/ou de mener à bien nos opérations
    commerciales, de communication, de marketing et de recrutement, tout en respectant le principe de proportionnalité
    selon lequel les données à caractère personnel ne doivent pas être conservées plus longtemps que la durée nécessaire
    à la réalisation de la finalité pour laquelle elles ont été collectées.
  </p>
  <p>
    L’ensemble des catégories de données personnelles faisant l’objet d’un traitement et les durées de détention y
    afférentes sont dûment répertoriées dans le registre des traitements de PortAgile que nous tenons à votre
    disposition sur demande.
  </p>
  <h2>
    Quels sont vos droits&nbsp;?
  </h2>
  <p>
    Conformément aux dispositions légales et réglementaires précitées, vous disposez d’un droit d’interrogation et
    d’accès à vos données.
  </p>
  <p>
    Vous bénéficiez également d’un droit de rectification, d’effacement et de limitation du traitement de vos données
    dans une certaine mesure, ainsi que du droit à la portabilité de vos données (càd transmission à un autre
    responsable de traitement).
  </p>
  <p>
    Vous disposez en outre d’un droit d’opposition à ce que les données à caractère personnel vous concernant fassent
    l’objet d’un traitement, et d’un droit d’opposition à ce que vos données soient utilisées à des fins de prospection
    notamment commerciale.
  </p>
  <p>
    Vous disposez également du droit de définir des directives générales et/ou particulières relatives au sort de vos
    données à caractère personnel et à la manière dont vous souhaitez que vos droits soient exercés après votre décès. A
    cet égard, en cas de décès qui serait porté à notre connaissance, vos données seront supprimées, sauf nécessité de
    conservation pendant une durée déterminée pour des motifs tenant à nos obligations légales et réglementaires et/ou
    aux délais légaux de prescription.
  </p>
  <p>
    Toutes demandes tendant à l’exercice de ces droits, ainsi que toutes demandes d’information concernant la protection
    des données à caractère personnel, peuvent être effectuées soit par les formulaires ci dessous, soit par courrier
    électronique à l’adresse gestion&#64;portagile.fr, soit par courrier postal auprès de PortAgile, 1 rue Jacques
    Prévert –
    59650 VILLENEUVE D’ASCQ, en mentionnant « RGPD » avant l’objet de votre demande. Ces demandes devront être
    accompagnées d’une copie d’un justificatif d’identité signé.
  </p>
  <p>
    PortAgile répondra à la personne ayant fait l’usage d’un des droits susvisés dans un délai d’un (1) mois à compter
    de la réception de la demande.
  </p>
  <p>
    Tout éventuel de refus du responsable du Traitement de donner suite à la demande d’information, opposition,
    rectification, effacement formulée par la personne concernée sera OBLIGATOIREMENT motivé[1].
  </p>
  <p>
    [1] Respect d’une obligation légale ou réglementaire, traitement statistique anonymisé, traitement et conservation
    nécessaires à l’exercice d’un droit en justice.
  </p>
  <h2>
    A qui s’adresser pour défendre ses droits&nbsp;?
  </h2>
  <p>
    PortAgile a désigné un délégué à la protection des données (DPO) : dpo&#64;portagile.fr
  </p>
  <p>
    Certains formulaires ou outils de simulation en ligne sur notre site proposent aux utilisateurs de saisir leur
    numéro de téléphone, adresse e-mail. L’utilisateur qui ne souhaite pas faire l’objet de prospection notamment
    commerciale par voie téléphonique est également informé de son droit à s’inscrire gratuitement sur la liste
    nationale d’opposition au démarchage téléphonique via le site internet accessible à l’url ici
    (https://www.bloctel.gouv.fr/).
  </p>
  <p>
    Sachez enfin que vous disposez de la possibilité d’introduire une réclamation auprès de l’autorité nationale en
    charge de la protection des données à caractère personnel (en France, il s’agit de la Commission Nationale de
    l’Informatique et des Libertés ou « Cnil ») si vous estimez que le traitement de vos données n’est pas effectué
    conformément aux dispositions applicables.
  </p>
  <p>
    Adresse postale de Commission nationale de l’Informatique et des Libertés : 3, Place de Fontenoy, 75007 Paris.
    https://www.cnil.fr/
  </p>
  <h2>
    Cookies et autres traceurs
  </h2>
  <p>
    Des cookies et autres traceurs ou technologies similaires (balise web, scripts intégrés, journaux de serveur web
    etc…) peuvent être installés et/ou lus dans votre navigateur lors de votre visite sur notre site. Retrouvez la
    politique des cookies ici https://www.portagile.fr/gestion-cookies
  </p>
  <p>
    PortAgile a recours à des services d’analyse web tiers, comme Google Analytics, pour l’aider à analyser la façon
    dont les personnes concernées utilisent son site.
  </p>
  <p>
    Pour en savoir plus sur la façon dont Google est à même d’utiliser les informations recueillies sur les sites web de
    PortAgile, nous vous invitons à prendre connaissance du lien suivant
    <a>https://policies.google.com/technologies/partner-sites?hl=fr</a>. Pour plus d’informations sur la façon de refuser la
    collecte de Données par Google Analytics, nous vous invitons à prendre connaissance du lien suivant
    <a>https://tools.google.com/dlpage/gaoptout</a>.
  </p>
  <p>
    Quelles sont les mesures mises en œuvre pour assurer la sécurité de vos données&nbsp;? Soucieuse de garantir la
    sécurité
    de vos données, PortAgile prend toutes les précautions et mesures utiles et appropriées, qu’elles soient physiques,
    logiques, techniques, fonctionnelles, administratives ou organisationnelles, au regard de l’état des connaissances,
    de la nature, de la portée, du contexte et des finalités du traitement ainsi que des risques, dont le degré de
    probabilité et de gravité varie, pour préserver la sécurité et la confidentialité des données et garantir un niveau
    de sécurité adapté au risque, et notamment empêcher qu’elles soient déformées, endommagées ou que des tiers non
    autorisés y aient accès.
  </p>
  <p>
    En raison des difficultés inhérentes à l’exercice d’une activité sur internet et des risques, que vous connaissez,
    résultant d’une transmission de données par voie électronique, PortAgile ne saurait toutefois être tenue à une
    obligation de résultat.
  </p>
  <p>
    En cas de survenance de difficultés, PortAgile fera ses meilleurs efforts pour circonscrire les risques et prendra
    toutes les mesures adéquates, conformément à ses obligations légales et réglementaires (actions correctrices,
    information de l’autorité nationale en charge de la protection des données à caractère personnel et le cas échéant
    des personnes concernées, …).
  </p>
  <p>
    Dans ce cadre, PortAgile certifie donc prendre des mesures pour limiter les actions intrusives de tiers (spam, etc…)
    et vous informera sur les éventuelles attaques électroniques (phishing, virus, rapt d’information,…) dont elle
    aurait fait l’objet.
  </p>
  <p>
    En cas de sous-traitance d’une partie ou de la totalité d’un traitement de données à caractère personnel (édition
    des paies par exemple), PortAgile impose contractuellement à ses sous-traitants des garanties de sécurité et
    notamment de confidentialité s’agissant des données à caractère personnel auxquels ils peuvent avoir accès (mesures
    techniques et organisationnelles appropriées de protection de ces données).
  </p>
  <h2>
    Quel est le sort de vos données sur les sites internet tiers&nbsp;?
  </h2>
  <p>
    Des liens hypertexte présents sur le site peuvent vous renvoyer vers des sites de tiers, extérieurs à PortAgile.
    Dans cette hypothèse, nous attirons votre attention sur le fait que les politiques de protection des données
    personnelles de ces sites peuvent être différentes de la nôtre.
  </p>
  <p>
    Aussi, il est recommandé, dans tous les cas, de prendre connaissance de la politique de protection des données
    personnelles de chacun des sites tiers concernés et, en tout état de cause, la responsabilité de PortAgile ne
    saurait être recherchée dans le cas où les traitements de données mis en œuvre via l’un de ces sites
    contreviendraient aux dispositions légales et réglementaires applicables.
  </p>
  <h2>
    Que faire pour être informé des mises à jour de la présente politique&nbsp;?
  </h2>
  <p>
    Cette politique de protection des données à caractère personnel est susceptible d’être modifiée ou ajustée à tout
    moment.
  </p>
  <p>
    En cas de modification ou d’ajustement, la nouvelle politique sera mise en ligne sur le site dans la rubrique
    dédiée.
  </p>
  <p>
    Nous vous invitons à la consulter régulièrement.
  </p>
  <p>
    Dernière modification : le 27/01/2023.
  </p>
</div>
