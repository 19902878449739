import { Component } from '@angular/core';
import {AppRoutes} from "../../../routing/app-routes.enum";
import {RouterModule} from '@angular/router';
import {NgClass, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-header-banner',
  standalone: true,
  imports: [
    RouterModule,
    NgOptimizedImage,
    NgClass,
  ],
  templateUrl: './header-banner.component.html',
  styleUrl: './header-banner.component.css'
})
export class HeaderBannerComponent {
  public readonly PORTAGILE_ROUTES_HOME: string = AppRoutes.HOME
  public readonly PORTAGILE_ROUTES_NOTRE_OFFRE: string = AppRoutes.NOTRE_OFFRE
  public readonly PORTAGILE_ROUTES_REMUNERATION: string = AppRoutes.REMUNERATION
  public readonly PORTAGILE_ROUTES_COOPTATION: string = AppRoutes.COOPTATION
  public readonly PORTAGILE_ROUTES_TROUVER_UNE_MISSION: string = AppRoutes.TROUVER_UNE_MISSION
  public readonly PORTAGILE_ROUTES_FOIRE_AUX_QUESTIONS: string = AppRoutes.FOIRE_AUX_QUESTIONS
  public readonly PORTAGILE_ROUTES_CONTACT: string = AppRoutes.CONTACT

  isMobileMenuOpen: boolean = false

  public toggleMenu(): void{
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  public closeMenu(): void{
    this.isMobileMenuOpen = false;
  }
}
