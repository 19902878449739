<app-h1-template [title]="'La cooptation'"/>

<div class="cooptationContainer">

  <h2>
    Des membres de votre réseau veulent se lancer en portage salarial&nbsp;?
  </h2>
  <h2 class="oval-background-orange">
    Parrainez-les et gagnez 750€&nbsp;💰
  </h2>

  <h3 class="oval-background-pink">
    Comment ça marche&nbsp;?
  </h3>

  <div class="cooptationContentBlock">
    <div class="numberBlock">
      <img class="number" ngSrc="assets/media/cooptation/1Number-150.webp" alt="1" height="270" width="150">
      <img class="underline" ngSrc="assets/media/cooptation/blueUnderline-200.webp" alt="soulignement" height="46" width="200">
    </div>
    <p class="textBlock"><strong class="spaceUnder">Contactez-nous&nbsp;:</strong><br>
      Envoyez un e-mail à contact&#64;portagile.fr pour nous informer de votre intention de parrainer un professionnel.
    </p>
    <div class="numberBlock">
      <img class="number" ngSrc="assets/media/cooptation/2Number-150.webp" alt="2" height="205" width="150">
      <img class="underline" ngSrc="assets/media/cooptation/pinkUnderline-200.webp" alt="soulignement" height="46" width="200">
    </div>
    <p class="textBlock"><strong class="spaceUnder">Parrainez&nbsp;:</strong><br>
      Une fois votre filleul devenu salarié chez PortAgile, vous recevez 750€ après 3 mois de prestation.
    </p>
    <div class="numberBlock">
      <img class="number" ngSrc="assets/media/cooptation/3Number-150.webp" height="230" alt="3" width="150">
      <img class="underline" ngSrc="assets/media/cooptation/orangeUnderline-200.webp" alt="soulignement" height="46" width="200">
    </div>
    <p class="textBlock"><strong>Sans limite de parrainage.</strong> Parlez-en autour de vous et augmentez vos gains&nbsp;!&nbsp;📈
    </p>
  </div>


  <h3 class="oval-background-blue">
    Et ce n'est pas tout&nbsp;!
  </h3>

  <div class="cooptationContentBlock">
    <p>
      Même si vous n'êtes pas salarié chez PortAgile, vous pouvez recevoir 750€ sous forme de cadeau en nous référant
      des professionnels intéressés par notre offre de portage salarial.&nbsp;🎁<br><br>
      Contactez-nous dès aujourd'hui pour plus d'informations&nbsp;!&nbsp;🚀
    </p>

  </div>
</div>
