<div class="simpleCalculatorForm-container">
  <div class="simpleCalculatorFormTitle">
    <h2>Simulation en ligne</h2>
  </div>

  <form [formGroup]="simpleCalculatorForm" class="simpleCalculatorForm" (ngSubmit)="onSubmit()">

    <div class="CAForm">
      <label for="revenue">Chiffre d'affaires Hors taxe (HT)&nbsp;:</label>
      <div class="radioButtons">
        <label for="revenueTypeJournalier" class="radioBox leftRadioBox">Journalier (TJM)
          <input id="revenueTypeJournalier" type="radio" formControlName="revenueType" value="journalier" (input)="showBillingDaysInput=true">
        </label>
        <label for="revenueTypeMensuel" class="radioBox rightRadioBox">Mensuel ou forfait
          <input id="revenueTypeMensuel" type="radio" formControlName="revenueType" value="mensuel" (input)="showBillingDaysInput=false">
        </label>
      </div>
      <div class="field euroAtEnd">
        <input id="revenue" class="no-spinner" min="1" type="number" formControlName="revenue"/>
        <div class="line"></div>
      </div>
    </div>

    <div *ngIf="this.showBillingDaysInput" class="billingDaysForm">
      <label for="billingDays">Nombre de jours facturés dans le mois&nbsp;:</label>
      <input id="billingDays" type="range" min="1" max="27" formControlName="billingDays" (input)="updateBillingDaysValue($event)">
      <div class="field dayAtEnd">
        <label for="billingDays2" class="sr-only">Nombre de jours facturés dans le mois:</label>
        <input id="billingDays2" class="no-spinner" type="number" min="1" max="27" formControlName="billingDays"
               (input)="updateBillingDaysValue($event)">
        <div class="line"></div>
      </div>
    </div>

    <div class="paidLeaveForm">
      <label>Congés payés&nbsp;:</label>
      <div class="radioButtons">
        <label for="paidLeaveYes" class="radioBox leftRadioBox">Oui
          <input id="paidLeaveYes" type="radio" formControlName="paidLeave" [value]="true">
        </label>
        <label for="paidLeaveNo" class="radioBox rightRadioBox">Non
          <input id="paidLeaveNo" type="radio" formControlName="paidLeave" [value]="false">
        </label>
      </div>
    </div>

    <div class="expensesForm">
      <label for="expenses">Frais professionnels&nbsp;:</label>
      <div class="field euroAtEnd">
        <input id="expenses" class="no-spinner" min="1" type="number" formControlName="expenses">
        <div class="line"></div>
      </div>
    </div>

    <div class="submitDiv">
      <button type="submit">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
        <span class="circle4"></span>
        <span class="circle5"></span>
        <span class="text">Simuler</span>
      </button>
    </div>

  </form>
</div>
