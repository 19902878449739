<div class="portagePresentationTitleSection hideForMobile">
  <h2 class="marginTitle">
    Vous souhaitez lancer&nbsp;votre <span class="oval-background-orange">activité&nbsp;indépendante</span>&nbsp;?
  </h2>
  <h2>
    Optez pour le <span class="oval-background-pink">Portage&nbsp;Salarial</span> et&nbsp;combinez&nbsp;:
  </h2>
</div>

<div class="portagePresentationTitleSection ShowForMobile">
  <h2 class="marginTitleMobile">
    Vous souhaitez lancer votre activité indépendante&nbsp;?
  </h2>
  <h2>
    Optez pour le Portage Salarial
  </h2>
</div>

<div class="portagePresentationFirstSection">
  <div class="portagePresentation-box">
    <div class="portagePresentation-box-content">
      <h3>La protection du statut de salarié</h3>
      <p>
        📝 Contrat de travail en CDI<br>
        🔓 Droits au chômage<br>
        🤒 Assurance Maladie<br>
        👨‍🦳 Cotisation Retraite<br>
        👪 Mutuelle et prévoyance<br>
        🛡️ Assurance RC Pro<br>
        🏖️ Congés payés<br>
        🏦 Accès facilité aux prêts bancaires<br>
      </p>
      <p class="yourStatusBottom">
        ➡️ Vous êtes salarié&nbsp;!
      </p>
    </div>
  </div>
  <svg class="plus" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24">
    <path fill="#FFF" stroke="#000" stroke-width="2"
          d="M15 9V4.5c0-.83-.67-1.5-1.5-1.5h-3C9.67 3 9 3.67 9 4.5V9H4.5C3.67 9 3 9.67 3 10.5v3c0 .83.67 1.5 1.5 1.5H9v4.5c0 .83.67 1.5 1.5 1.5h3c.83 0 1.5-.67 1.5-1.5V15h4.5c.83 0 1.5-.67 1.5-1.5v-3c0-.83-.67-1.5-1.5-1.5H15Z"></path>
  </svg>
  <div class="portagePresentation-box">
    <div class="portagePresentation-box-content">
      <h3>Les avantages de l’indépendance</h3>
      <p>
        🌍 Autonomie<br>
        💰 Avantage financier de l’indépendant<br>
      </p>
      <p class="yourStatusBottom">
        ➡️ Vous êtes indépendant&nbsp;!
      </p>
    </div>
  </div>
</div>
<div class="portagePresentationSeparator">
  <div class="custom-shape-divider-bottom-1717591936">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200,0H0V120H281.94C572.9,116.24,602.45,3.86,602.45,3.86h0S632,116.24,923,120h277Z"
            class="shape-fill"></path>
    </svg>
  </div>
  <div class="custom-shape-divider-bottom-1717591892">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M602.45,3.86h0S572.9,116.24,281.94,120H923C632,116.24,602.45,3.86,602.45,3.86Z"
            class="shape-fill"></path>
    </svg>
  </div>
</div>

<div class="logoTriangle">
  <img ngSrc="assets/brand/logo-600.webp" width="600" height="598" alt="PortAgile logo" priority>
</div>

<div class="portagePresentationSecondSection">
  <div class="portagePresentation-box" id="portagilePresentation-box">
    <div class="logoInBox">
      <img ngSrc="assets/brand/logo-300.webp" width="300" height="299" alt="PortAgile logo">
    </div>
    <div class="contentInBox">
      <h3> Le portage salarial avec PortAgile </h3>
      <p>
        📊 Gestion administrative prise en charge par PortAgile<br><br>
        💸 Zéros frais de lancement et de fermeture pour votre activité<br><br>
        🧾Possibilité de déduire des frais professionnels jusqu’à 20% de votre CA<br>
      </p>
      <p class="yourStatusBottom">
        ➡️ Vous êtes salarié porté&nbsp;!
      </p>
    </div>
  </div>
</div>
