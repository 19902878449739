import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-trouver-une-mission',
  standalone: true,
  imports: [
    H1TemplateComponent,
    NgOptimizedImage
  ],
  templateUrl: './trouver-une-mission.component.html',
  styleUrl: './trouver-une-mission.component.css'
})
export class TrouverUneMissionComponent {

}
