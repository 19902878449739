import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {NgOptimizedImage} from "@angular/common";
import {ContactFormComponent} from "../../components/actions-components/contact-form/contact-form.component";
import {CooptationFormComponent} from "../../components/actions-components/cooptation-form/cooptation-form.component";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    H1TemplateComponent,
    NgOptimizedImage,
    ContactFormComponent,
    CooptationFormComponent
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent {

}
