import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NgApexchartsModule} from "ng-apexcharts";

import {
  ApexChart,
  ApexPlotOptions,
  ApexDataLabels,
  ApexNonAxisChartSeries,
  ApexLegend
} from "ng-apexcharts";
import {NgOptimizedImage} from "@angular/common";
import {SimpleCalculatorModel} from "../../../models/simple-calculator.model";

@Component({
  selector: 'app-simple-calculator-result',
  standalone: true,
  imports: [
    NgApexchartsModule,
    NgOptimizedImage,
  ],
  templateUrl: './simple-calculator-result.component.html',
  styleUrl: './simple-calculator-result.component.css'
})
export class SimpleCalculatorResultComponent implements OnChanges {
  @Input() resultData: SimpleCalculatorModel | null = null;

  chart: ApexChart = {
    width: 304,
    height: 320,
    type: "donut",
  };

  chartPlotOptions: ApexPlotOptions = {
    pie: {
      startAngle: -180,
      endAngle: 180,
      expandOnClick: false,
      donut: {
        size: '65%'
      }
    }
  }

  chartDataLabels: ApexDataLabels = {
    style: {
      fontSize: '1.1em'
    },
    textAnchor: "start",
    offsetX: 100,
    formatter: (val: number) => `${Math.round(val)}%`,
  }

  chartColors: string[] = ["#E52A6E", "#14C3CE", "#FF891C"];

  chartSeries: ApexNonAxisChartSeries = [0, 0, 0];

  chartLabels: string[] = ["Pour vous", "Frais de gestion PortAgile", "Charges"];

  chartLegend: ApexLegend = {
    show: false
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resultData'] && changes['resultData'].currentValue) {
      this.updateChartSeries();
    }
  }

  updateChartSeries(): void {
    this.chartSeries = [
      this.resultData?.forYou ?? 0,
      this.resultData?.portAgileManagementFees ?? 0,
      this.resultData?.charges ?? 0
    ];
  }
}
