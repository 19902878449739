<div class="footerBanner">
  <div class="footerContent">
    <div class="footerContentGlobalSection">
      <div class="footerContentSection1">

        <img ngSrc="assets/brand/logo-300.webp" width="300" height="299" alt="PortAgile logo">
        PortAgile<br> Agilité et Transparence<br> pour votre portage salarial
      </div>
      <div class="footerContentSection2">
        <a href="mailto:contact@portagile.fr">
          <div class="infoFooterRaw">
            <img class="footerIcon" ngSrc="assets/icons/mail-icon.svg" width="60" height="60" alt="mail">
            contact&#64;portagile.fr
          </div>
        </a>
        <div class="infoFooterRaw">
          <img class="footerIcon" ngSrc="assets/icons/phone-icon.svg" width="40" height="40" alt="téléphone">
          (+33) 9 67 82 35 88
        </div>
        <div class="infoFooterRaw">
          <img class="footerIcon" ngSrc="assets/icons/map-icon.svg" width="43" height="50" alt="map">
          1 rue Jacques Prévert<br>
          59650 Villeneuve d'Ascq<br> Hauts de France, FRANCE
        </div>
        <div class="footerW100">
          <div class="infoFooterRaw columnFooterBlock">
            Votre partenaire dans l'IT :
            <a href="https://e-nov.info" target="_blank" rel="noopener noreferrer" class="footerEnov">
              <img ngSrc="../../../../assets/brand/e-nov/e-novReverse-200.webp" alt="logo société E-NOV" height="56"
                   width="200"/>
            </a>
          </div>
          <div class="infoFooterRaw columnFooterBlock">
            Nos actus sur LinkedIn :
            <a href="https://www.linkedin.com/company/portagile/" target="_blank" rel="noopener noreferrer" class="footerEnov">
              <img ngSrc="../../../../assets/media/global/linkedInLogo-200.webp" alt="logo société E-NOV" height="52"
                   width="199"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footerCopyright">
    <div class="footerCopyrightContent">
      <span>Copyright © 2023 PortAgile.<br class="hideAboveXSDevice"> All Rights Reserved by PortAgile.</span>
      <div class="copyrightMenu">
        <a [routerLink]="[PORTAGILE_ROUTES_CGU]">Politique de confidentialité</a>
        <a [routerLink]="[PORTAGILE_ROUTES_CGU]">Mentions légales - CGU</a>
        <a [routerLink]="[PORTAGILE_ROUTES_COOKIES]">Gestion des préférences cookies</a>
      </div>
    </div>
  </div>
</div>
