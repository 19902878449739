import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-cooptation',
  standalone: true,
  imports: [
    H1TemplateComponent,
    NgOptimizedImage
  ],
  templateUrl: './cooptation.component.html',
  styleUrl: './cooptation.component.css'
})
export class CooptationComponent {

}
