<div class="fepsContainer">
  <h2>PortAgile est adhérant à la FEPS</h2>
  <img class="fepsLogo" ngSrc="assets/media/offer-pictures/feps-200.webp" alt="Logo de la FEPS" height="116"
       width="198"/>
  <div class="fepsBlock">
    <img class="fepsGroupPicture" ngSrc="assets/media/offer-pictures/fepsPicture-500.webp"
         alt="Photo d'une assemblée FEPS" height="500"
         width="500"/>
    <p>La FEPS c’est quoi&nbsp;?<br>

      La Fédération des Entreprises de Portage Salarial (FEPS) est une organisation qui assure le respect du cadre légal
      de la convention collective du portage salarial.<br><br>

      En tant que membre de la FEPS, PortAgile s'engage à assurer son sérieux et son professionnalisme. Cette
      affiliation vous offre une garantie supplémentaire dans le choix d'un partenaire fiable pour vous accompagner dans
      votre vie professionnelle en tant qu'indépendant.</p>
  </div>
</div>
