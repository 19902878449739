import {Component, OnInit} from '@angular/core';

declare var $: any; // Déclaration de jQuery

@Component({
  selector: 'app-detailed-explanation',
  standalone: true,
  imports: [],
  templateUrl: './detailed-explanation.component.html',
  styleUrl: './detailed-explanation.component.css'
})
export class DetailedExplanationComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    // Fonctionnalités jQuery
    $('.process-wrapper').on('click', '.step', (event: any) => {
      $('.step').removeClass('active visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step01', () => {
      $('#line-progress').css('width', '1%');
      $('.step01Content').addClass('active').siblings().removeClass('active');
      $('.step01').addClass('active');
      $('#triangleArrowRight').removeClass('step03').addClass('step02');
      $('#triangleArrowLeft').removeClass('step01 step02');
      $('.step01, .step02, .step03').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step02', () => {
      $('#line-progress').css('width', '6.66%');
      $('.step02Content').addClass('active').siblings().removeClass('active');
      $('.step02, .step01').addClass('active');
      $('#triangleArrowRight').removeClass('step02 step04').addClass('step03');
      $('#triangleArrowLeft').removeClass('step02').addClass('step01');
      $('.step01, .step02, .step03').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step03', () => {
      $('#line-progress').css('width', '13.32%');
      $('.step03Content').addClass('active').siblings().removeClass('active');
      $('.step03, .step02').addClass('active');
      $('#triangleArrowRight').removeClass('step02 step03 step05').addClass('step04');
      $('#triangleArrowLeft').removeClass('step01 step03').addClass('step02');
      $('.step02, .step03, .step04').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step04', () => {
      $('#line-progress').css('width', '19.98%');
      $('.step04Content').addClass('active').siblings().removeClass('active');
      $('.step04, .step03').addClass('active');
      $('#triangleArrowRight').removeClass('step04 step06').addClass('step05');
      $('#triangleArrowLeft').removeClass('step02 step04').addClass('step03');
      $('.step03, .step04, .step05').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step05', () => {
      $('#line-progress').css('width', '26.64%');
      $('.step05Content').addClass('active').siblings().removeClass('active');
      $('.step05, .step04').addClass('active');
      $('#triangleArrowRight').removeClass('step05 step07').addClass('step06');
      $('#triangleArrowLeft').removeClass('step03 step05').addClass('step04');
      $('.step04, .step05, .step06').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step06', () => {
      $('#line-progress').css('width', '33.3%');
      $('.step06Content').addClass('active').siblings().removeClass('active');
      $('.step06, .step05').addClass('active');
      $('#triangleArrowRight').removeClass('step06 step08').addClass('step07');
      $('#triangleArrowLeft').removeClass('step04 step06').addClass('step05');
      $('.step05, .step06, .step07').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step07', () => {
      $('#line-progress').css('width', '39.96%');
      $('.step07Content').addClass('active').siblings().removeClass('active');
      $('.step07, .step06').addClass('active');
      $('#triangleArrowRight').removeClass('step07 step09').addClass('step08');
      $('#triangleArrowLeft').removeClass('step05 step07').addClass('step06');
      $('.step06, .step07, .step08').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step08', () => {
      $('#line-progress').css('width', '46.62%');
      $('.step08Content').addClass('active').siblings().removeClass('active');
      $('.step08, .step07').addClass('active');
      $('#triangleArrowRight').removeClass('step08 step10').addClass('step09');
      $('#triangleArrowLeft').removeClass('step06 step08').addClass('step07');
      $('.step07, .step08, .step09').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step09', () => {
      $('#line-progress').css('width', '53.28%');
      $('.step09Content').addClass('active').siblings().removeClass('active');
      $('.step09, .step08').addClass('active');
      $('#triangleArrowRight').removeClass('step09 step11').addClass('step10');
      $('#triangleArrowLeft').removeClass('step07 step09').addClass('step08');
      $('.step08, .step09, .step10').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step10', () => {
      $('#line-progress').css('width', '59.94%');
      $('.step10Content').addClass('active').siblings().removeClass('active');
      $('.step10, .step09').addClass('active');
      $('#triangleArrowRight').removeClass('step10 step12').addClass('step11');
      $('#triangleArrowLeft').removeClass('step08 step10').addClass('step09');
      $('.step09, .step10, .step11').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step11', () => {
      $('#line-progress').css('width', '66.6%');
      $('.step11Content').addClass('active').siblings().removeClass('active');
      $('.step11, .step10').addClass('active');
      $('#triangleArrowRight').removeClass('step11 step13').addClass('step12');
      $('#triangleArrowLeft').removeClass('step09 step11').addClass('step10');
      $('.step10, .step11, .step12').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step12', () => {
      $('#line-progress').css('width', '73.26%');
      $('.step12Content').addClass('active').siblings().removeClass('active');
      $('.step12, .step11').addClass('active');
      $('#triangleArrowRight').removeClass('step12 step14').addClass('step13');
      $('#triangleArrowLeft').removeClass('step10 step12').addClass('step11');
      $('.step11, .step12, .step13').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step13', () => {
      $('#line-progress').css('width', '79.92%');
      $('.step13Content').addClass('active').siblings().removeClass('active');
      $('.step13, .step12').addClass('active');
      $('#triangleArrowRight').removeClass('step13 step15').addClass('step14');
      $('#triangleArrowLeft').removeClass('step11 step13').addClass('step12');
      $('.step12, .step13, .step14').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step14', () => {
      $('#line-progress').css('width', '86.58%');
      $('.step14Content').addClass('active').siblings().removeClass('active');
      $('.step14, .step13').addClass('active');
      $('#triangleArrowRight').removeClass('step14 step16').addClass('step15');
      $('#triangleArrowLeft').removeClass('step12 step14 step15').addClass('step13');
      $('.step13, .step14, .step15').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step15', () => {
      $('#line-progress').css('width', '93.24%');
      $('.step15Content').addClass('active').siblings().removeClass('active');
      $('.step15, .step14').addClass('active');
      $('#triangleArrowRight').removeClass('step15').addClass('step16');
      $('#triangleArrowLeft').removeClass('step13 step15').addClass('step14');
      $('.step14, .step15, .step16').addClass('visibleStepPoint');
    });

    $('.process-wrapper').on('click', '.step16', () => {
      $('#line-progress').css('width', '100%');
      $('.step16Content').addClass('active').siblings().removeClass('active');
      $('.step16, .step15, .step14').addClass('active');
      $('#triangleArrowRight').removeClass('step15 step16');
      $('#triangleArrowLeft').removeClass('step14 step13').addClass('step15');
      $('.step14, .step15, .step16').addClass('visibleStepPoint');
    });
  }

}
