import {Component} from '@angular/core';
import {LandingComponent} from "../../components/content/landing/landing.component";
import {
  DetailedExplanationComponent
} from "../../components/content/detailed-explanation/detailed-explanation.component";
import {NgOptimizedImage} from "@angular/common";
import {HowItWorksComponent} from "../../components/content/how-it-works/how-it-works.component";
import {
  PortagePresentationComponent
} from "../../components/content/portage-presentation/portage-presentation.component";
import {TeamComponent} from "../../components/content/team/team.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LandingComponent,
    DetailedExplanationComponent,
    NgOptimizedImage,
    HowItWorksComponent,
    PortagePresentationComponent,
    TeamComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {

}
