<app-h1-template [title]="'Gestion des préférences cookies'"/>
<div class="cookiesContainer">
  <p>
    PortAgile est susceptible d’installer des cookies sur vos équipements lors de vos visites sur le site Internet et
    d’utiliser des cookies sur les courriers électroniques qui vous sont adressés. La présente Politique cookies a
    vocation à vous communiquer des informations sur les différents cookies utilisés, quelles sont leurs finalités et
    quels sont vos droits notamment s’agissant du paramétrage. Des cookies ou témoins de connexion sont utilisés sur le
    site Internet et temporairement conservés sur le terminal informatique de l’utilisateur tel que les ordinateurs, les
    tablettes et/ou les smartphones pour faciliter la navigation et optimiser la qualité du site Internet. Ces cookies
    peuvent être refusés par l’utilisateur sous réserve des choix et options que l’utilisateur peut exprimer à tout
    moment. Les cookies utilisés sur ce site Internet peuvent être mis en place soit par notre site Internet, soit par
    un
    site tiers. Ces cookies peuvent être de session ou persistants : un cookie de session est un cookie qui est
    automatiquement supprimé lorsque l’utilisateur ferme le navigateur, alors qu’un cookie persistant reste stocké dans
    l’ordinateur de l’utilisateur jusqu’à sa date d’expiration. Les cookies utilisés sur le site Internet ont une durée
    de
    vie maximale de 12 mois.
  </p>
  <h2>
    CATÉGORIES
  </h2>
  <p>
    Nous utilisons certains types de cookies sur ce site Internet :Les cookies techniques et de session obligatoires :
    Ces
    cookies sont indispensables afin de pouvoir naviguer sur notre site Internet et bénéficier pleinement de ses
    fonctionnalités telles que l’accès à certaines pages Internet sécurisées. Sans ces cookies, certains services que
    vous
    demandez ne pourront vous être fournis.
  </p>
  <p>
    Les cookies fonctionnels et de performance : Ces cookies permettent au site Internet d’enregistrer vos choix de
    paramétrage afin de vous fournir des fonctionnalités améliorées et plus personnelles. Ces cookies peuvent également
    être utilisés afin de mémoriser les modifications que vous avez apportées à la taille du texte, la police et
    d’autres
    parties des pages Internet que vous avez personnalisées. Ils peuvent également être utilisés afin de vous fournir
    certains services dont vous avez fait la demande comme regarder une vidéo.
  </p>
  <p>
    Ces cookies ne peuvent pas suivre votre activité de navigation sur d’autres sites Internet et ne recueillent aucune
    information vous concernant qui pourrait être utilisée à des fins publicitaires.
  </p>
  <p>
    Les cookies de mesure d’audience : Les cookies sont utilisés afin de recueillir des informations sur la façon dont
    les
    visiteurs utilisent notre site Internet telles que les pages les plus visitées ou l’apparition de messages d’erreur
    sur certaines pages. Toutes les informations recueillies par le biais de ces cookies sont anonymisées, et collectées
    dans le seul but de développer et de suivre les tendances du trafic sur notre site Internet afin d’améliorer la
    façon
    dont il fonctionne.
  </p>
  <p>
    Ces informations font l’objet d’un usage exclusif par PortAgile et ne sont aucunement partagées avec des tiers.
  </p>
  <p>
    Les cookies de publicité ciblée : Les cookies de ciblage sont utilisés pour mesurer l’efficacité d’une campagne
    publicitaire en permettant la diffusion de publicités ciblées à l’écoute des utilisateurs et dont la récurrence
    d’apparition est limitée.
  </p>
  <h2>
    UTILISATION
  </h2>
  <p>
    Les cookies déposés ou utilisés peuvent entrainer la collecter de données à caractère personnel notamment les
    données
    d’identification (adresse IP), de connexion, d’acceptation, d’identité et de coordonnées.
  </p>
  <p>
    Des sites tiers de médias sociaux sont susceptibles de collecter certaines informations (telles que l’adresse IP, le
    type de navigateur, la langue utilisée, ou l’heure d’accès) si vous êtes connectés à ces sites tout en naviguant sur
    notre site Internet. Ces sites Internet peuvent également lier ces informations collectées avec les informations de
    votre profil disponibles sur leur propre site. Nous ne contrôlons pas ces technologies de suivi tiers, ainsi nous
    vous
    recommandons de prendre connaissance des conditions d’utilisation et de la politique de Protection des Données
    Personnelles de ces sites tiers avant de les utiliser.
  </p>
  <p>
    D’autres cookies peuvent recueillir des Données Personnelles (y compris les informations de cookies placés par nos
    liens publicitaires sur des sites tiers) :
  </p>
  <p>
    – Si un utilisateur est un utilisateur inscrit ; – Si nous vous envoyons un courrier électronique ciblé comprenant
    des
    balises Internet, des cookies ou des technologies similaires, nous saurons si vous ouvrez, lisez ou supprimez ce
    message.
  </p>
  <h2>
    AUTRE SUIVI
  </h2>
  <p>
    PortAgile utilise également des technologies sensiblement similaires aux cookies telles que des balises Internet ou
    toute autre technologie pouvant figurer dans des courriers électroniques publicitaires ou des newsletters afin de
    déterminer si ces messages ont bien été consultés ou si vous avez cliqué sur les liens qu’ils contiennent. Les
    balises
    Internet ne déposent aucune donnée sur votre ordinateur, mais peuvent être utilisés en parallèle des cookies afin
    d’analyser l’activité de navigation du site.
  </p>
  <h2>
    ACCEPTER OU REFUSER LES COOKIES
  </h2>
  <p>
    Nous vous rappelons toutefois que la désactivation des cookies peut conduire à l’altération ou la perte de certains
    réglages ou informations. La suppression des cookies peut être de nature à modifier, voire à rendre difficile la
    navigation sur le site web.
  </p>
  <p>
    Si vous ne souhaitez pas recevoir de cookies de la part de notre site Internet, vous pouvez également configurer
    votre
    navigateur. Pour ce faire, veuillez suivre les instructions appropriées ci-dessous en fonction de votre navigateur :
  </p>
  <p>
    – Si vous utilisez le navigateur Internet Explorer
  </p>
  <p>
    Choisissez le menu « Outils » (ou « Tools »), puis « Options Internet » (ou « Internet Options »). Cliquez sur
    l’onglet « Confidentialité » (ou « Confidentiality »). Sélectionnez le niveau souhaité à l’aide du curseur.
  </p>
  <p>
    – Si vous utilisez le navigateur Firefox
  </p>
  <p>
    Choisissez le menu « Outils » > « Options ». Cliquez sur l’option « Vie privée ».
  </p>
  <p>
    – Si vous utilisez le navigateur Safari
  </p>
  <p>
    Choisissez le menu « Editions ». Cliquez sur l’option « Préférences ». Cliquez sur l’option « Sécurité ». Cliquez
    sur
    « Afficher les cookies ».
  </p>
  <p>
    – Si vous utilisez le navigateur Google Chrome
  </p>
  <p>
    Sur votre ordinateur, ouvrez Chrome. En haut à droite, cliquez sur « Plus Paramètres ». Sous « Confidentialité et
    sécurité », cliquez sur « Cookies et autres données de site ». Cliquez sur « Afficher l’ensemble des cookies et
    données de sites » Tout supprimer. Confirmez la suppression en cliquant sur « Tout effacer ».
  </p>
  <p>
    Dernière modification : le 27/01/2023. Elle peut être modifiée à tout moment en cas d’évolution légales,
    jurisprudentielles ou des recommandations de la CNIL
  </p>
</div>
