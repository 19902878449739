import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [
    H1TemplateComponent
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.css'
})
export class PrivacyPolicyComponent {

}
