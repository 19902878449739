import { Component } from '@angular/core';
import {H1TemplateComponent} from "../../components/header/h1-template/h1-template.component";
import {OfferAdvantagesComponent} from "../../components/content/offer-advantages/offer-advantages.component";
import {ManagementFeesComponent} from "../../components/content/management-fees/management-fees.component";
import {FEPSComponent} from "../../components/content/feps/feps.component";

@Component({
  selector: 'app-notre-offre',
  standalone: true,
  imports: [
    H1TemplateComponent,
    OfferAdvantagesComponent,
    ManagementFeesComponent,
    FEPSComponent
  ],
  templateUrl: './notre-offre.component.html',
  styleUrl: './notre-offre.component.css'
})
export class NotreOffreComponent {

}
