import {Component, AfterViewInit} from '@angular/core';
import Swiper from 'swiper';
import {Autoplay, EffectCoverflow, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-offer-advantages',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './offer-advantages.component.html',
  styleUrl: './offer-advantages.component.css'
})

export class OfferAdvantagesComponent implements AfterViewInit {
  ngAfterViewInit() {
    new Swiper('.swiper', {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      loop: true,
      // spaceBetween: 120,
      coverflowEffect: {
        rotate: 30,
        stretch: 100,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      modules: [Navigation, Pagination, EffectCoverflow],
    });
  }
}
