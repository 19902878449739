export class SimpleCalculatorModel {
  // Input fields
  revenueType: string;
  revenue: number;
  billingDays: number;
  paidLeave: boolean;
  professionalExpenses: number = 0;

  // Used variables
  managementFeesPercentage: number = 0.05;
  occupationalHealthContributions: number = 89;
  assuranceRCPPercentage: number = 0.1464;
  mandatoryMutualInsurance: number = 0; // (default = exempt) solo = 41.53
  mandatoryMutualEmployerSharePercentage: number = 0.5;
  mandatoryMutualSalarySharePercentage: number = 0.5;
  restaurantVouchersEmployerContributionsPercentage: number = 0.6;
  restaurantVouchersSalaryCostsPercentage: number = 0.4;
  restaurantVouchersFaceValue: number = 10;
  employerOvertimeDeduction: number = 1.5;
  weeklyRhythm = 39;
  defaultEmployerChargePercentage = 0.35;
  financialReservePercentage = 0.1;
  paidLeaveReservePercentage = 0.1;
  salaryCostsReservePercentage = 0.21;
  mutualExtension = 0;
  overtimeSocialExemptionsPercentage = 0.1131;
  generalizedSocialContributionTaxReliefPercentage = 0.0175;
  withholdingTaxPercentage: number = 0.11;


  // Generated variables
  grossSalary: number = 0;
  otherCharge: number = 0;
  mandatoryMutualEmployerShare: number = this.mandatoryMutualEmployerSharePercentage * this.mandatoryMutualInsurance;
  mandatoryMutualSalaryShare: number = this.mandatoryMutualSalarySharePercentage * this.mandatoryMutualInsurance;
  monthlyRestaurantVouchersFaceValue: number = 0;
  monthlyRestaurantVouchersEmployerContributions: number = 0;
  monthlyRestaurantVouchersSalaryCosts: number = 0;
  monthlyOvertime: number = 0;
  monthlyOvertimeDeduction: number = 0;
  overallRateOfProvisions: number = 0;
  salaryCostsExcludingRestaurantVouchers: number = 0;
  grossOvertimeDeduction: number = 0;
  baseSalary: number = 0;
  withholdingTax: number = 0;
  generalizedSocialContributionBase: number = 0;
  nonDeductibleCSGCRDSPercentage: number = 0.029;

  // Output fields
  turnover: number = 0;
  forYou: number = 0;
  portAgileManagementFees: number = 0;
  charges: number = 0;
  netSalary: number = 0;
  // + professionalExpenses
  financialReserve: number = 0;
  paidLeaveReserve: number = 0;

  constructor(data: {
    revenueType: string;
    revenue: number;
    billingDays: number;
    paidLeave: boolean;
    expenses: number;
  }) {
    this.revenueType = data.revenueType;
    this.revenue = data.revenue;
    this.billingDays = data.billingDays;
    this.paidLeave = data.paidLeave;
    this.professionalExpenses = data.expenses;

    this.calculateFields();
  }

  calculateFields() {
    // output fields :

    // CA :
    if (this.revenueType === 'journalier') {
      this.turnover = this.revenue * this.billingDays;
    } else {
      this.turnover = this.revenue;
    }

    // Frais de gestion PortAgile :
    this.portAgileManagementFees = this.turnover * this.managementFeesPercentage;

    // Salaire net :
    this.otherCharge = (this.occupationalHealthContributions / 12) + (this.assuranceRCPPercentage * this.turnover * 0)
    this.monthlyRestaurantVouchersFaceValue = this.restaurantVouchersFaceValue * this.billingDays;
    this.monthlyRestaurantVouchersEmployerContributions = this.monthlyRestaurantVouchersFaceValue * this.restaurantVouchersEmployerContributionsPercentage;
    this.monthlyRestaurantVouchersSalaryCosts = this.monthlyRestaurantVouchersFaceValue * this.restaurantVouchersSalaryCostsPercentage;
    if (this.weeklyRhythm > 35) {
      this.monthlyOvertime = (this.weeklyRhythm - 35) * 52 / 12;
    }
    this.monthlyOvertimeDeduction = this.monthlyOvertime * this.employerOvertimeDeduction;
    if (this.paidLeave) {
      this.overallRateOfProvisions = this.defaultEmployerChargePercentage + (this.paidLeaveReservePercentage * (1 + this.defaultEmployerChargePercentage)) + (this.financialReservePercentage / 1.05)
    } else {
      this.overallRateOfProvisions = this.defaultEmployerChargePercentage + (this.financialReservePercentage / 1.155)
    }
    this.grossSalary = (this.turnover - this.portAgileManagementFees - this.professionalExpenses - this.otherCharge - this.mandatoryMutualEmployerShare - this.monthlyRestaurantVouchersEmployerContributions + this.monthlyOvertimeDeduction) / (1 + this.overallRateOfProvisions);
    if (this.paidLeave) {
      this.baseSalary = (this.grossSalary) / 1.05;
    } else {
      this.baseSalary = (this.grossSalary - (this.grossSalary * 0.1 / 1.1)) / 1.05;
    }
    if (this.weeklyRhythm > 35) {
      this.grossOvertimeDeduction = this.baseSalary * (this.weeklyRhythm - 35) / 35;
    }
    this.salaryCostsExcludingRestaurantVouchers = this.grossSalary * this.salaryCostsReservePercentage + this.mutualExtension + (this.mandatoryMutualInsurance * this.mandatoryMutualEmployerSharePercentage) - this.grossOvertimeDeduction * this.overtimeSocialExemptionsPercentage;
    this.generalizedSocialContributionBase = this.grossSalary * (1 - this.generalizedSocialContributionTaxReliefPercentage) + this.mandatoryMutualEmployerShare;
    this.withholdingTax = (this.grossSalary - this.salaryCostsExcludingRestaurantVouchers + this.generalizedSocialContributionBase * this.nonDeductibleCSGCRDSPercentage + this.mandatoryMutualSalaryShare + this.mutualExtension - this.grossOvertimeDeduction) * this.withholdingTaxPercentage;
    this.netSalary = this.grossSalary - this.salaryCostsExcludingRestaurantVouchers - this.monthlyRestaurantVouchersSalaryCosts - this.withholdingTax;

    // professionalExpenses define already in the constructor

    // Réserve financière :
    this.financialReserve = this.baseSalary * this.financialReservePercentage;

    // Réserve congés payés :
    if (this.paidLeave) {
      this.paidLeaveReserve = this.grossSalary * (this.paidLeaveReservePercentage * (1 + this.defaultEmployerChargePercentage));
    }

    // Pour vous :
    this.forYou = this.netSalary + this.professionalExpenses + this.financialReserve + this.paidLeaveReserve;

    this.charges = this.turnover - this.forYou - this.portAgileManagementFees;

    this.turnover = parseFloat(this.turnover.toFixed(0));
    this.netSalary = parseFloat(this.netSalary.toFixed(0));
    this.professionalExpenses = parseFloat(this.professionalExpenses.toFixed(0));
    this.financialReserve = parseFloat(this.financialReserve.toFixed(0));
    this.paidLeaveReserve = parseFloat(this.paidLeaveReserve.toFixed(0));
    this.forYou = parseFloat(this.forYou.toFixed(0));
    this.portAgileManagementFees = parseFloat(this.portAgileManagementFees.toFixed(0));
    this.charges = parseFloat(this.charges.toFixed(0));

    // console.log(this);
  }
}
